import {extendTheme} from "@chakra-ui/react"

const ChakraTheme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "#f8f8f8"
            }
        }
    },
    fonts: {
        heading: "Roboto, sans-serif",
        body: "Roboto, sans-serif",
    },
    colors: {
        secondary: '#e40e34',
        secondaryLighter: '#e0e3eb',
        brand: '#1db9cc',
        orange: '#eb731a',
        cta: '#D10818'
    },
    components: {
        Progress: {
            baseStyle: {
                filledTrack: {
                    bg: '#69091a'
                }
            }
        },
        Input: {
            defaultProps: {
                focusBorderColor: 'black', // Set focus border color
            },
            baseStyle: {
                field: {
                    borderColor: 'black', // Default border color
                    _hover: {
                        borderColor: 'black', // Hover border color
                    }
                }
            }
        }
    }
})

export default ChakraTheme;
