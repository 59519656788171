import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const DeclineChart = ({ title, initialValue, declinePercent, color }) => {
    const targetValue = initialValue * (1 - declinePercent); // Calculate target after decline
    const weeks = 5;

    // Calculate weekly values for the specified decline over 5 weeks
    const dataValues = Array.from({ length: weeks }, (_, i) =>
        initialValue * (1 - (declinePercent * (i + 1) / weeks))
    );

    const data = {
        labels: ['Woche 1', 'Woche 2', 'Woche 3', 'Woche 4', 'Woche 5'],
        datasets: [
            {
                label: title,
                data: dataValues,
                fill: false,
                borderColor: color,
                tension: 0.3,
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointRadius: 5,
                pointHoverRadius: 7,
            },
            {
                label: `${Math.round(declinePercent * 100)}% Ziel`,
                data: Array(weeks).fill(targetValue),
                borderColor: 'rgba(255,99,132,0.5)',
                borderDash: [5, 5],
                pointRadius: 0,
                fill: false,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: true,
                text: `${title} - Wöchentliche Abnahme`,
            },
        },
        scales: {
            y: {
                min: 80,
                max: initialValue,
                title: {
                    display: true,
                    text: `${title} (%)`,
                },
                ticks: {
                    callback: (value) => `${value}%`,
                },
            },
        },
    };

    return <Line data={data} options={options} />;
};

export default DeclineChart;
