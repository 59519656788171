import Logo from '../../assets/home/logo.svg';
import {
    Box,
    Button,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    List,
    ListIcon,
    ListItem,
    Text
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import useStore from "../../storage/DataStore";
import {CheckIcon} from "@chakra-ui/icons";

function Home({testMode, showLegal}) {
    let navigate = useNavigate();
    const intl = useIntl();
    const nextStep = function () {
        navigate('/step1', {replace: true});
    }

    const testPlan = function () {
        useStore.setState({
            gender: 'male',
            weight: 100,
            targetWeight: 80,
            bodyHeight: 180,
            age: '30'
        });

        navigate('/step8', {replace: true});
    }

    return (
        <>
            {showLegal &&
                <Box textAlign={'center'} background={'#e6e6e6'} fontSize={'10px'} p={2}>
                    {intl.formatMessage({id: 'legal-stuff.header'})}
                </Box>
            }

            {testMode &&
                <Flex justifyContent={'center'}>
                    <Button onClick={testPlan} bg={'red'} color={'white'} my={3}>
                        TEST
                    </Button>
                </Flex>
            }

            <Flex backgroundColor={'#e40e34'} alignItems={'center'} justifyContent={'center'} py={3} mb={4}>
                <Image height={8} src={Logo} alt={'Phytorin®'} className="logo" display={'inline-block'}/>
            </Flex>

            <Container>
                <Heading lineHeight={['2.2rem','3.5rem']} fontSize={['1.5rem', '2.3em']} textAlign={'center'}>
                    Individuelle Prognose für die Senkung Ihres Blutdrucks und Unterstützung Ihrer
                    Herz-Kreislauf-Gesundheit
                </Heading>

                <Text my={5} fontSize={['1rem', 'xl']} textAlign={'center'}>
                    Die Wirksamkeit einer Anwendung von Phytosterolen zur natürlichen Blutdrucksenkung ist durch Studien
                    belegt. Auf dieser Datengrundlage können wir die voraussichtliche Zeit berechnen, die Sie für eine
                    merkbare Verbesserung Ihrer Blutdruckwerte benötigen.
                </Text>

                <List spacing={3}>
                    <ListItem>
                        <Grid templateColumns="auto 1fr" alignItems="start" gap={2}>
                            <GridItem>
                                <ListIcon height={6} width={6} as={CheckIcon} color="green.500" />
                            </GridItem>
                            <GridItem>
                                Finden Sie heraus, wie sich Ihre Herz-Kreislauf-Gesundheit durch die Anwendung
                                von <strong>PHYTORIN</strong> positiv verändern kann.
                            </GridItem>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid templateColumns="auto 1fr" alignItems="start" gap={2}>
                            <GridItem>
                                <ListIcon height={6} width={6} as={CheckIcon} color="green.500" />
                            </GridItem>
                            <GridItem>
                                Erfahren Sie, ob <strong>PHYTORIN</strong> für Sie persönlich geeignet ist und wieviel Zeit Sie
                                benötigen, um von einer stabilen Blutdrucksenkung zu profitieren.
                            </GridItem>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid templateColumns="auto 1fr" alignItems="start" gap={2}>
                            <GridItem>
                                <ListIcon height={6} width={6} as={CheckIcon} color="green.500" />
                            </GridItem>
                            <GridItem>
                                Eine Anwendung mit <strong>PHYTORIN</strong> ist derzeit freiverkäuflich und ohne Rezept
                                erhältlich.
                            </GridItem>
                        </Grid>
                    </ListItem>
                </List>

                <Flex justifyContent={'center'}>
                    <Button bg="secondary"
                            color={"white"}
                            _hover={{
                                bg: "#69091a"
                            }}
                            size="lg"
                            my={5}
                            p={7}
                            width={['100%', '70%']}
                            fontSize={'x-large'}
                            type="submit"
                            onClick={nextStep}>
                        Prognose starten
                    </Button>
                </Flex>
            </Container>
        </>
    );
}

export default Home;