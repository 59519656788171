import { Box, useRadio } from "@chakra-ui/react";
import React from "react";

function RadioCard(props) {
    const { getInputProps } = useRadio(props);

    const input = getInputProps();

    return (
        <Box as="label">
            <input {...input} />

            <Box
                cursor="pointer"
                borderRadius="md"
                background={"secondary"}
                color={"white"}
                _hover={{
                    bg: "#69091a"
                }}
                _checked={{
                    bg: "#69091a"
                }}
                _focus={{
                    bg: "#69091a"
                }}
                px={[2, 5]}
                py={[3, 5]}
            >
                {props.children}
            </Box>
        </Box>
    );
}

export default RadioCard;
