import './Loader.scss';
import {Progress, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import Step from "../../components/step/Step";

function Loader() {
    const intl = useIntl();

    const [percentage, setPercentage] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (percentage >= 100) {
            navigate('../step6', {replace: true});
        } else {
            const increment = () => {
                setPercentage(prevPercentage => {
                    // Calculate remaining percentage to 100
                    const remaining = 100 - prevPercentage;

                    // Random increment that slows down near 100%
                    const incrementAmount = Math.min(
                        Math.random() * (remaining / 5) + 1, // Dynamic increment
                        remaining
                    );

                    return prevPercentage + incrementAmount;
                });
            };

            // Random interval between increments to make it feel less mechanical
            const interval = setInterval(increment, Math.random() * 200 + 200);

            return () => clearInterval(interval);
        }
    }, [percentage, navigate, intl]);

    return (
        <Step currentStep={7} backLink={'/step6'}>
            <Text mb={4}>
                {intl.formatMessage({id: 'loader.text'})}
            </Text>
            <Progress isIndeterminate colorScheme='orange' height='32px' value={Math.min(percentage, 100)} />
        </Step>
    );
}

export default Loader;