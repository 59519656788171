import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';

import ChakraTheme from "./templates/Chakra";
import PhytorinTemplate from "./templates/Phytorin";

import Home from "./pages/home/Home";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Step6 from "./pages/step6/Step6";
import Loader from "./pages/loader/Loader";

function App() {
    const currentUrl = window.location.toString();
    const testMode = currentUrl.search('tstmd') !== -1;

    let messages = German;
    let locale = 'de';
    moment.locale('de');

    let trust7Locale = 'de-DE';

    document.title = messages.title + ' | Phytorin®';

    // load trust7 widget sdk and ensure it is only loaded once
    if (!document.getElementById('trust7-sdk')) {
        const trust7 = document.createElement('script');
        trust7.setAttribute('id', 'trust7-sdk');
        trust7.setAttribute('type', 'module');
        trust7.setAttribute('src', `https://elements.trust7.app/${trust7Locale}/widget-elements.js`);
        document.head.appendChild(trust7);
    }

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <PhytorinTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home testMode={testMode}/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2/>
                            }/>
                            <Route path='/step3' element={
                                <Step3/>
                            }/>
                            <Route path='/step4' element={
                                <Step4/>
                            }/>
                            <Route path='/step5' element={
                                <Step5/>
                            }/>
                            <Route path='/step6' element={
                                <Step6/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                        </Routes>
                    </PhytorinTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
