import "./Phytorin.scss";
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400.css'

function PhytorinTemplate(props) {
    return (
        <div className={"page phytorin"}>
            {props.children}
        </div>
    );
}

export default PhytorinTemplate;
