import Step from "../../components/step/Step";
import {Text, Heading, Box} from "@chakra-ui/react";
import DeclineChart from "../../components/chart/DeclineChart";
import Products from "../../components/products/Products";
import CtaButton from "../../components/cta-button/CtaButton";

function Step6() {
    let outgoingLink = 'https://buypcaps.com/de/jetzt-kaufen.html';

    if (window.location.search !== '') {
        outgoingLink += window.location.search;
    }

    return (
        <Step currentStep={6}
              backLink={'/step5'}>
            <Heading lineHeight={['2.2rem','3.5rem']} fontSize={['1.5rem', '2.3em']} textAlign={'center'}>
                Mit einer unterstützenden Anwendung von Phytorin könnte der Blutdruck um bis zu 10% und der
                LDL-Cholestinspiegel um 5 bis 15% gesenkt werden.
            </Heading>

            <Box my={5}>
                <Box boxShadow='lg' p={['2','6']} rounded='md'>
                    <DeclineChart
                        title="Blutdruck"
                        initialValue={100}
                        declinePercent={0.10}
                        color="rgba(75,192,192,1)"
                    />
                </Box>

                <Box boxShadow='lg' p={['2','6']} rounded='md'>
                    <DeclineChart
                        title="LDL-Cholesterinspiegel"
                        initialValue={100}
                        declinePercent={0.15}
                        color="rgba(255,159,64,1)"
                    />
                </Box>
            </Box>

            <Box textAlign={'left'} fontSize={'0.9rem'} px={10}>
                <Text my={5}>
                    <strong>Wichtiger Hinweis:</strong> Wenn Sie verschriebene Medikamente gegen Bluthochdruck
                    einnehmen, setzen Sie diese auf keinen Fall eigenmächtig ab, auch nicht während der Anwendung von
                    PHYTORIN.
                </Text>
                <Text my={5}>
                    Eine Anpassung oder das Absetzen von ärztlich verordneten Medikamenten darf ausschließlich nach
                    Rücksprache mit Ihrem Arzt erfolgen.
                </Text>
                <Text>
                    Bitte handeln Sie keinesfalls auf eigene Faust – die richtige Dosierung und ärztliche Begleitung
                    sind entscheidend für Ihre Sicherheit und Gesundheit.
                </Text>
            </Box>

            <Box my={5}>
                <Heading>
                    Ihre persönliche Produktempfehlung
                </Heading>

                <Box pt={5}>
                    <Products />
                </Box>
            </Box>

            <CtaButton link={outgoingLink}
                       text="Mehr erfahren"/>
        </Step>
    );
}

export default Step6;
