import Step from "../../components/step/Step";
import {SimpleGrid, Text, useRadioGroup} from "@chakra-ui/react";
import RadioCard from "../../components/radio-card/RadioCard";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";

function Step4() {
    let navigate = useNavigate();
    const intl = useIntl();

    const {getRootProps, getRadioProps} = useRadioGroup({
        name: 'q4',
        onChange: () => {
            navigate('../step5', {replace: true});
        }
    });

    const options = [
        {
            'id': 'a1',
            'text': intl.formatMessage({id: 'step4.option1'}, {br: <br/>})
        },
        {
            'id': 'a2',
            'text': intl.formatMessage({id: 'step4.option2'}, {br: <br/>})
        },
        {
            'id': 'a3',
            'text': intl.formatMessage({id: 'step4.option3'}, {br: <br/>})
        },
        {
            'id': 'a4',
            'text': intl.formatMessage({id: 'step4.option4'}, {br: <br/>})
        }
    ];

    const group = getRootProps()

    return (
        <Step heading={intl.formatMessage({id: 'step4.headline'})}
              currentStep={4}
              backLink={'/step3'}>

            <SimpleGrid mt={5} spacing={2} columns={1} {...group}>
                {options.map((value) => {
                    const radio = getRadioProps({'value': value.id})
                    return (
                        <RadioCard key={value.id} {...radio}>
                            <Text fontSize={'lg'} fontWeight={'bold'}>{value.text}</Text>
                        </RadioCard>
                    )
                })}
            </SimpleGrid>
        </Step>
    );
}

export default Step4;
